:root {
  --accent-color: rgba(214, 77, 131, 1); /* Accent color */
  --accent-gradient: linear-gradient(
    to right,
    rgb(244, 204, 241),
    rgba(246, 157, 231, 0.5)
  ); /* Gradient using the accent color */
  --light-bg: rgba(242, 242, 242, 1); /* Light background color */
  --accent-color-100: rgba(
    245,
    152,
    229,
    0.1
  ); /* Accent color with 10% opacity */
  --accent-color-200: rgba(
    214,
    77,
    131,
    0.2
  ); /* Accent color with 20% opacity */
  --accent-color-300: rgba(
    214,
    77,
    131,
    0.3
  ); /* Accent color with 30% opacity */
  --accent-color-400: rgba(
    214,
    77,
    131,
    0.4
  ); /* Accent color with 40% opacity */
  --accent-color-500: rgba(
    214,
    77,
    131,
    0.5
  ); /* Accent color with 50% opacity */
  --accent-color-600: rgba(
    214,
    77,
    131,
    0.6
  ); /* Accent color with 60% opacity */
  --accent-color-700: rgba(
    214,
    77,
    131,
    0.7
  ); /* Accent color with 70% opacity */
  --accent-color-800: rgba(
    214,
    77,
    131,
    0.8
  ); /* Accent color with 80% opacity */
  --accent-color-900: rgba(
    214,
    77,
    131,
    0.9
  ); /* Accent color with 90% opacity */
  --text-color: rgba(255, 255, 255, 0.9); /* Light text color */
  --text-color-primary: rgb(2, 0, 0); /* Primary text color */
}

.header {
  background-color: var(--light-bg);
  padding: 1rem 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
}

.header-logo {
  color: var(--accent-color);
}

.header-navigation {
  color: var(--accent-color-700);
  gap: 1.5rem;
}

.nav-link {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--accent-color);
}

/* Coming Soon Section */
.coming-soon-section {
  color: var(--text-color-primary);
  padding: 2rem;
  background: var(--accent-gradient);
}

.coming-soon-content {
  max-width: 800px;
  margin: 0 auto;
}

.coming-soon-heading {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

/* Countdown Cards */
.countdown-cards {
  display: flex;
  gap: 1rem;
}

.countdown-card {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 8px;
  color: var(--accent-color-900);
}

.countdown-number {
  font-size: 2rem;
  font-weight: bold;
}

.countdown-label {
  font-size: 1rem;
}

/* Waitlist Form */
.waitlist-card {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 2rem;
  background: rgba(255, 255, 255, 0.9);
  padding: 3rem !important  ;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.8);

  input {
    background-color: white;
    width: 100%;
    color: var(--text-color-primary);
  }
}

.form-heading {
  width: 100%;
  text-align: center;
  margin: 1.5rem 0px 2.7rem;
  font-size: 1.5rem;
}

.waitlist-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.form-field label {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
}

.submit-button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 4px;
  width: 100% !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: rgba(214, 77, 131, 0.8);
}

.waitlist-info {
  text-align: center;
  margin-top: 1rem;
}

.features-section {
  padding: 2rem;
  background-color: var(--light-bg);
}

.features-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.feature-card {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  padding: 1.5rem;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 300px;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  margin-bottom: 1rem;
}

.icon-circle {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-gradient);
  border-radius: 50%;
  color: white;
  font-size: 1.5rem;
}

.feature-heading {
  margin-top: 0.5rem;
  color: var(--accent-color);
}

.feature-description {
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
}

.footer {
  background-color: #1a1a1a; /* Dark background color */
  color: white;
  padding: 2rem 1rem;
}

.footer-container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--accent-color); /* Accent color defined in root */
}

.footer-year {
  margin-top: 1rem;
  color: white !important;
}

@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/mauve.css";

/* reset */
input,
textarea,
button {
  all: unset;
  box-sizing: border-box;
}

.FormRoot {
  width: 260px;
}

.FormField {
  display: grid;
  margin-bottom: 10px;
  width: 100%;
}

.FormLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 35px;
  color: white;
}

.FormMessage {
  font-size: 13px;
  color: white;
  opacity: 0.8;
}

.Input,
.Textarea {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 15px;
  color: white;
  background-color: var(--black-a2);
  box-shadow: 0 0 0 1px var(--black-a6);
}

.Input:hover,
.Textarea:hover {
  box-shadow: 0 0 0 1px black;
}

.Input:focus,
.Textarea:focus {
  box-shadow: 0 0 0 2px black;
}

.Input::selection,
.Textarea::selection {
  background-color: var(--black-a6);
  color: white;
}

.Input {
  padding: 0 10px;
  height: 35px;
  line-height: 1;
}

.Textarea {
  resize: none;
  padding: 10px;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  width: 100%;
  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a4);
}

.Button:hover {
  background-color: var(--mauve-3);
}

.Button:focus {
  box-shadow: 0 0 0 2px black;
}

.heart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: var(--accent-color-900); /* Circle background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for aesthetics */
}

.heart-icon {
  width: 50%; /* Adjust heart size relative to the circle */
  height: 50%;
}

/* .toast {
  z-index: 1000;
  position: absolute;
  width: 300px;
  background-color: white;
  color: var(--accent-color-800);
  right: 0;
  top: 80vh;
  padding: 2rem;
}
 */

@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/violet.css";

/* reset */
button {
  all: unset;
}

.ToastViewport {
  --viewport-padding: 25px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.ToastRoot {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: grid;
  grid-template-areas: "title action" "description action";
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;
}
.ToastRoot[data-state="open"] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state="closed"] {
  animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe="cancel"] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe="end"] {
  animation: swipeOut 100ms ease-out;
}

@media (max-width: 752px) {
  .countdown-cards {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }

  .features-container {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .form-heading {
    margin: 0.5rem 0px 1rem;
  }
}
