.sidebar-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 40px;
  background: #2a2d34;
  color: white;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-open {
  width: 200px;
  background: #2a2d34;
  color: white;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.active {
  background: rgba(255, 255, 255, 0.1);
}

nav {
  width: 100%;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  width: 100%;

  a {
    display: flex;
    align-items: center;
  }
}

nav li {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  transition: background 0.3s;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

nav li svg {
  margin-right: 10px;
}

nav li:hover {
  background: rgba(255, 255, 255, 0.1);
}
